import React from 'react';
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';
import { Container, Header, Image, Menu, Message, Segment } from 'semantic-ui-react';
import { SelectionContainer, ResultsContainer, PromotionContainer } from './components';
import 'semantic-ui-css/semantic.min.css';
import logo from './resources/logo.png';
import icon from './resources/icon.jpg';

const App = () => (
  <Router>
    <Segment>
      <Menu fixed="top" inverted>
        <Container>
          <Menu.Item as={Link} header to="/">
            <Image src={icon} size="mini" circular style={{ marginRight: 10 }} />
            Carcinomix
          </Menu.Item>
          <Menu.Item as={Link} to="/analyze">
            Analyze
          </Menu.Item>
        </Container>
      </Menu>
    </Segment>
    <Container style={{ paddingTop: 100, paddingBottom: 50 }}>
      <Header as="h2" icon textAlign="center">
        <Link to="/">
          <Image src={logo} size="large" centered />
        </Link>
      </Header>
    </Container>
    <Container>
      <Routes>
        <Route exact path="/" element={<PromotionContainer />} />
        <Route exact path="/analyze" element={<SelectionContainer />} />
        <Route path="/results/:id" element={<ResultsContainer />} />
      </Routes>
    </Container>
    <footer>
      <Container>
        <Message
          icon="warning circle"
          header="Disclaimer"
          content="This resource is intended for research purposes and it must not substitute a doctor´s medical judgement or healthcare professional advice. Carcinomix provides this data and software in good faith, but make no warranty, expressed or implied, nor assume any legal liability or responsibility for any purpose for which they are used"
        />
      </Container>
      <p>© {new Date().getFullYear()} Carcinomix - all rights reserved.</p>
    </footer>
  </Router>
);

export default App;

// MEDICAL DISCLAIMER
// The information contained in this cancer genome report is intended for research purposes only. We make no representations or warranties of any kind, expressed or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the genome report or the information, products, services, for interpretation or use in clinical practice, or otherwise contained in the report for any purpose. Any reliance you place on information in the report is therefore strictly at your own risk. In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this genome report.
