import axios from "axios";
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { FixedSizeList } from "react-window";
import { useNavigate } from "react-router-dom";
import { Accordion, Button } from "semantic-ui-react";
import "./Selection.css";
import { useEffect } from "react";
import { useRef } from "react";

// const axiosInstance = axios.create({
//   baseURL: "/v1",
// });

const ListItem = ({ data, index, isHeader, style = {} }) => {
  const { items, handler } = data;
  const item = items[index];

  const [selected, setSelected] = useState(item.selected || false);

  useEffect(() => {
    setSelected(item.selected);
  }, [item.selected]);

  const handleSelection = (item, e) => {
    if (handler) {
      item.selected = !item.selected;
      setSelected(item.selected);
      handler(item, e);
    }
  };
  return (
    <div
      className={`listItem ${selected ? "selected" : ""} ${
        isHeader ? "listHeader" : ""
      }`}
      selected={item.selected}
      onClick={(e) => handleSelection(item, e)}
      style={style}
    >
      {item.content.map((part, partIndex) => (
        <div key={partIndex} className="part">
          {part}
        </div>
      ))}
    </div>
  );
};

const SelectionList = ({ content, header, internalRef }) => (
  <div>
    <ListItem isHeader data={{ items: [header] }} index={0} />
    <FixedSizeList
      height={500}
      itemCount={content.length}
      itemData={{ items: content, handler: () => {} }}
      itemSize={40}
      ref={internalRef}
    >
      {ListItem}
    </FixedSizeList>
  </div>
);

const Selection = () => {
  const [content, setContent] = useState(null);
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [header, setHeader] = useState(null);
  const listRef = useRef();

  const navigate = useNavigate();

  const submitSelection = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("description", JSON.stringify(description));
    const selection = Object.values(content).reduce((accu, item) => {
      const subselection = item.items.filter(
        (thang) => thang.selected === true
      );
      return accu.concat(subselection);
    }, []);
    formData.append("selection", JSON.stringify(selection));
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post(`${process.env.REACT_APP_BACKEND}/v1/files/`, formData, config)
      .then((response) => {
        setLoading(false);
        navigate(`/results/${response.data.id}`, {
          state: { requestName: description.name },
        });
      })
      .catch(console.log);
  };

  const handleFiles = (accepted, rejected) => {
    const file = accepted[0];
    const reader = new FileReader();
    reader.onload = () => {
      const description = {
        name: file.name,
        lastModified: file.lastModified,
      };

      const fileContents = reader.result.split("\n");
      const header = {
        content: fileContents.shift().split("\t"),
        selected: false,
      };
      const content = fileContents.reduce((accu, item) => {
        if (!item) {
          return accu;
        }
        const read = item.split("\t");
        const readGroup = read[0];
        let group = accu[readGroup];
        if (!group) {
          accu[readGroup] = { items: [], selected: false };
          group = accu[readGroup];
        }
        group.items.push({ content: read, selected: false });
        return accu;
      }, {});
      setFile(file);
      setDescription(description);
      setContent(content);
      setHeader(header);
    };
    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");

    reader.readAsBinaryString(file);
  };

  const toggle = (index, value) => {
    const item = content[index];
    item.selected = value;
    item.items.map((thing) => (thing.selected = value));
    setContent(content);
    listRef.current.forceUpdate();
  };

  const panels = !!file
    ? Object.entries(content).map((entry, index) => ({
        key: `panel-${entry[0]}-${index}`,
        title: {
          content: (
            <span key={`selection-header-${entry[0]}-${index}`}>
              {entry[0]}
            </span>
          ),
        },
        content: {
          content: (
            <div>
              <Button onClick={() => toggle(entry[0], true)} attached="left">
                Select all
              </Button>
              <Button onClick={() => toggle(entry[0], false)} attached="right">
                Deselect all
              </Button>
              <SelectionList
                style={{ minHeight: 400 }}
                header={header}
                content={entry[1].items}
                key={`selection-list-${entry[0]}-${index}`}
                internalRef={listRef}
              />
            </div>
          ),
        },
      }))
    : [];
  return (
    <div style={{ marginBottom: 20 }}>
      <form>
        <div>
          {!file && (
            <Dropzone
              accept={{ "text/html": [".VFResults", ".vfresults"] }}
              onDrop={handleFiles}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className="dropzone">
                  <input {...getInputProps()} />
                  <p>
                    Drop your VFResults here or click to select them from your
                    machine.
                  </p>
                  <p>Only *.VFResults files will be accepted</p>
                </div>
              )}
            </Dropzone>
          )}
        </div>
        <div>
          {!!file && (
            <div>
              <label>File Contents:</label>
              <Accordion fluid styled exclusive={false} panels={panels} />
              <Button
                primary
                loading={loading}
                disabled={loading}
                onClick={submitSelection}
              >
                Submit
              </Button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default Selection;
