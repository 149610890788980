import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { Container, Header } from "semantic-ui-react";
import Results from "../Results/Results";
import "./ResultsContainer.css";

export const ResultsContainer = () => {
  const { state } = useLocation();
  const { id: requestId } = useParams();
  const fileName = state ? state.requestName : "";
  return (
    <Container style={{ minHeight: "50vh" }}>
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "space-between",
        }}
      >
        <Header as="h3">Tumor/Normal Input File:</Header>
        <Header as="h3">{fileName}</Header>
      </div>
      <div className="container">
        <Results requestId={requestId} />
      </div>
    </Container>
  );
};

export default ResultsContainer;
