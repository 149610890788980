import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Card, CardHeader, Grid, CardContent } from 'semantic-ui-react';

const DnaIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M2 15c6.667-6 13.333 0 20-6" />
    <path d="M9 22c1.798-1.998 2.518-3.995 2.807-5.993" />
    <path d="M15 2c-1.798 1.998-2.518 3.995-2.807 5.993" />
    <path d="m17 6-2.5-2.5" />
    <path d="m14 8-1-1" />
    <path d="m7 18 2.5 2.5" />
    <path d="m3.5 14.5.5.5" />
    <path d="m20 9 .5.5" />
    <path d="m6.5 12.5 1 1" />
    <path d="m16.5 10.5 1 1" />
    <path d="m10 16 1.5 1.5" />
  </svg>
);

const HeartIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z" />
  </svg>
);

export const PromotionContainer = () => (
  <Container>
    <header>
      <Link href="#">
        <DnaIcon />
        <span>Precision Genomics</span>
      </Link>
      <nav>
        <Grid>
          <Grid.Row>
            {[
              { title: 'Services', location: '#services' },
              { title: 'Technology', location: '#technology' }
            ].map(({ location, title }) => (
              <Link href={location} key={title}>
                {title}
              </Link>
            ))}
          </Grid.Row>
        </Grid>
      </nav>
    </header>
    <main>
      <section>
        <Container>
          <p>Revolutionizing healthcare through precision genomics.</p>
          <Link to="/analyze">Explore DNA Analytics</Link>
        </Container>
      </section>
      <section id="services">
        <Container>
          <div>
            <h2>Transforming Healthcare</h2>
            <p>
              Our precision genomics services are designed to provide detailed, personalized insights that can help healthcare providers make more informed
              decisions.
            </p>
          </div>
          <Grid celled>
            <Grid.Row>
              {[
                { title: 'Genomic Analysis', description: 'Advanced data analysis to interpret genomic data and provide actionable insights.', Icon: DnaIcon },
                { title: 'Personalized Healthcare', description: 'Personalized healthcare plans based on individual genetic profiles.', Icon: HeartIcon }
              ].map(({ title, description, Icon }) => (
                <Grid.Column key={title} width={3}>
                  <Card>
                    <Icon />
                    <CardHeader>{title}</CardHeader>
                    <CardContent>{description}</CardContent>
                  </Card>
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
        </Container>
      </section>
      <section id="technology">
        <Container>
          <div>
            <div>Our Technology</div>
            <h2>Cutting-Edge Genomics</h2>
            <p>We utilize the latest technologies in genomics and data science to provide the most accurate and comprehensive genomic analysis.</p>
          </div>
        </Container>
      </section>
    </main>
  </Container>
);

export default PromotionContainer;
