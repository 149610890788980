import axios from "axios";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import "./Results.css";
import { Button, Loader, Table } from "semantic-ui-react";

const headerContent = `Chr	Pos	Ref	Alt	Variant	RefAA	AltAA	Gene	Reference Study	Study Type	Line of Treatment	Treatment Agent	Mutation Status	Patients in Study	Response Rate	Progression Free Months	Overall Survival Months`;

const ListItem = ({ item, itemCount }) => {
  let parts = [];
  if (item.chromosome) {
    parts = Object.values(item);
  } else {
    parts = item.split("\t");
  }

  const arr = Array(itemCount).fill("");
  const theRealContent = arr.map((thing, index) =>
    parts[index] ? parts[index] : thing
  );
  // const tinyParts = [2,3,7,8];
  return (
    <Table.Row>
      {theRealContent.map((part, index) => (
        <Table.Cell key={index} className="part">
          {part}
        </Table.Cell>
      ))}
    </Table.Row>
  );
};

export const Results = ({ requestId }) => {
  const transform = useCallback((result) => {
    const hasMore = result && result.content.length < result.context;
    setContent(result ? result.content : []);
    setHasMore(hasMore);
    setIsRequesting(!result);
  }, []);

  const transformResults = useCallback((response) => {
    const result = response.data.content.map((item) => ({
      chromosome: item.chromosome,
      position: item.position,
      ref: item.ref,
      alt: item.alt,
      consequence: item.Consequence,
      refAa: item.refAa,
      altAa: item.altAa,
      gene: item.gene,
      // queries: item.queries ? item.queries.join(',') : '',
      studies: item.studies ? item.studies[0] : "",
    }));
    return { content: result, context: response.data.total };
  }, []);

  const getAnalysisDataFor = useCallback(
    (id, page = 1, per_page = 100) =>
      axios
        .get(
          `${process.env.REACT_APP_BACKEND}/v1/files/${id}?page=${page}&per_page=${per_page}`
        )
        .then(transformResults)
        .catch(console.log),
    [transformResults]
  );

  useEffect(() => {
    getAnalysisDataFor(requestId).then(transform);
  }, [requestId, transform, getAnalysisDataFor]);

  const refresh = window.setInterval(() => {
    if (!isRequesting) {
      getAnalysisDataFor(requestId).then(transform);
    }
  }, 30 * 1000); // check if results are in

  const [content, setContent] = useState([]);
  const [headerLength] = useState(headerContent.split("\t").length);
  const [isLoading, setIsLoading] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const [per_page] = useState(100);
  const [header] = useState(headerContent.split("\t"));

  const handleClick = () => {
    setIsLoading(true);
    getAnalysisDataFor(requestId, page + 1, per_page).then((result) => {
      setContent([...content, ...result.content]);
      const hasMore = content.length < result.context;
      setPage(page + 1);
      setIsLoading(false);
      setHasMore(hasMore);
    });
  };

  if (content.length) {
    clearInterval(refresh);
  }
  const resultItems = content.map((item, index) => (
    <ListItem key={index} itemCount={headerLength} item={item} />
  ));
  return (
    <div>
      {resultItems.length ? (
        <>
          <Table size="small" unstackable>
            <Table.Header>
              <Table.Row>
                {header.map((part, index) => {
                  return (
                    <Table.HeaderCell key={index}>{part}</Table.HeaderCell>
                  );
                })}
              </Table.Row>
            </Table.Header>
            <Table.Body>{resultItems}</Table.Body>
          </Table>
          {!hasMore ? (
            <></>
          ) : (
            <>
              {isLoading ? (
                <Button basic fluid loading>
                  Loading...
                </Button>
              ) : (
                <Button basic fluid onClick={handleClick}>
                  load more
                </Button>
              )}
            </>
          )}
        </>
      ) : (
        <Loader active size="large" inline="centered">
          Analyzing...
        </Loader>
      )}
    </div>
  );
};

Results.propTypes = {
  requestId: PropTypes.string,
};

export default Results;
